import Rest from '@/services/Rest';

/**
 * @typedef {EnterpriseFileBrokerServiceNoLogin}
 */
export default class EnterpriseFileBrokerServiceNoLogin extends Rest {
  /**
   * @type {String}
   */
  static resource = '/no-login/broker/enterprise'
}
