import Rest from '@/services/Rest';

/**
 * @typedef {EnterpriseFileService}
 */
export default class EnterpriseFileCountService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/v2'
}
