<script>

export default {
    name: 'Spinner',
    props: {
        size: {
            type: String,
            default: 45
        }
    }
}

</script>

<template>

    <img 
        src="@/assets/img/spinner.gif"
        :width="size" 
        :height="size"
    />

</template>